import React from "react";
const Result = ({ data }) => {
  let newData = data.hasOwnProperty("Subnet") ? true : false;
  if (data.hasOwnProperty("Subnet") || data.hasOwnProperty("Supernet")) {
    return (
      <div className="table-responsive">
        {newData ? (
          <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="table-dark text-light" scope="col">
                    Address Name
                  </th>
                  <th className="table-dark  text-light" scope="col">
                    Host or Network
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="table-active" scope="row">
                    Address
                  </th>
                  <td className="table-active">{data.Address}</td>
                </tr>
                <tr>
                  <th className="table-active" scope="row">
                    Netmask
                  </th>
                  <td className="table-active">
                    {data.Netmask} --- {data.NetmaskLength}
                  </td>
                </tr>
                <tr>
                  <th className="table-active" scope="row">
                    Wildcard
                  </th>
                  <td className="table-active">{data.Wildcard}</td>
                </tr>
                <tr>
                  <th className="bg-dark"></th>
                  <td className="bg-dark"></td>
                </tr>

                <tr>
                  <th className="table-active text-primary" scope="row">
                    Network
                  </th>
                  <td className="table-active text-primary">{data.Network}</td>
                </tr>
                <tr>
                  <th className="table-active text-primary" scope="row">
                    Broadcast
                  </th>
                  <td className="table-active text-primary">
                    {data.Broadcast}
                  </td>
                </tr>
                <tr>
                  <th className="table-active text-primary" scope="row">
                    HostMin
                  </th>
                  <td className="table-active text-primary">{data.HostMin}</td>
                </tr>
                <tr>
                  <th className="table-active text-primary" scope="row">
                    HostMax
                  </th>
                  <td className="table-active text-primary">{data.HostMax}</td>
                </tr>
                <tr>
                  <th className="table-active text-primary" scope="row">
                    HostsNet
                  </th>
                  <td className="table-active text-primary">
                    {data.HostsNet}
                    <span style={{ color: "#D35400", marginLeft: "0.5em" }}>
                      ({data.IsPrivate ? "Private" : "Public"})
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h1 className="text-info font-weight-bold">Subnets:</h1>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="table-dark text-light" scope="col">
                      Address Name
                    </th>
                    <th className="table-dark  text-light" scope="col">
                      Host or Network
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="table-active" scope="row">
                      Netmask
                    </th>
                    <td className="table-active">
                      {data.Subnet.Netmask} --- {data.Subnet.NetmaskLength}
                    </td>
                  </tr>

                  <tr>
                    <th className="table-active" scope="row">
                      Wildcard
                    </th>
                    <td className="table-active">{data.Subnet.Wildcard}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {data.Subnet.Items.map((i) => (
              <>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="bg-primary text-light" scope="col">
                          Address Name
                        </th>
                        <th className="bg-primary text-light" scope="col">
                          Host or Network
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="table-dark" scope="row">
                          Network
                        </th>
                        <td className="table-dark">{i.Network}</td>
                      </tr>
                      <tr>
                        <th className="table-dark" scope="row">
                          Broadcast
                        </th>
                        <td className="table-dark">{i.Broadcast}</td>
                      </tr>
                      <tr>
                        <th className="table-dark" scope="row">
                          HostMin
                        </th>
                        <td className="table-dark">{i.HostMin}</td>
                      </tr>
                      <tr>
                        <th className="table-dark" scope="row">
                          HostMax
                        </th>
                        <td className="table-dark">{i.HostMax}</td>
                      </tr>
                      <tr>
                        <th className="table-dark" scope="row">
                          HostsNet
                        </th>
                        <td className="table-dark">
                          {i.HostsNet}
                          <span
                            style={{ color: "#D35400", marginLeft: "0.5em" }}
                          >
                            ({i.IsPrivate ? "Private" : "Public"})
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ))}
            <h5 className="text-info font-weight-bold">
              Subnets:{data.Subnet.Items.length}
            </h5>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="table-dark text-light" scope="col">
                      Address Name
                    </th>
                    <th className="table-dark  text-light" scope="col">
                      Host or Network
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="table-active" scope="row">
                      Address
                    </th>
                    <td className="table-active">{data.Address}</td>
                  </tr>
                  <tr>
                    <th className="table-active" scope="row">
                      Netmask
                    </th>
                    <td className="table-active">
                      {data.Netmask} --- {data.NetmaskLength}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-active" scope="row">
                      Wildcard
                    </th>
                    <td className="table-active">{data.Wildcard}</td>
                  </tr>
                  <tr>
                    <th className="bg-dark"></th>
                    <td className="bg-dark"></td>
                  </tr>

                  <tr>
                    <th className="table-active text-primary" scope="row">
                      Network
                    </th>
                    <td className="table-active text-primary">
                      {data.Network}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-active text-primary" scope="row">
                      Broadcast
                    </th>
                    <td className="table-active text-primary">
                      {data.Broadcast}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-active text-primary" scope="row">
                      HostMin
                    </th>
                    <td className="table-active text-primary">
                      {data.HostMin}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-active text-primary" scope="row">
                      HostMax
                    </th>
                    <td className="table-active text-primary">
                      {data.HostMax}
                    </td>
                  </tr>
                  <tr>
                    <th className="table-active text-primary" scope="row">
                      HostsNet
                    </th>
                    <td className="table-active text-primary">
                      {data.HostsNet}
                      <span style={{ color: "#D35400", marginLeft: "0.5em" }}>
                        ({data.IsPrivate ? "Private" : "Public"})
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h1 className="font-weight-bold text-info">Supernet</h1>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="bg-primary text-dark" scope="col">
                      Address Name
                    </th>
                    <th className="bg-primary text-dark" scope="col">
                      Host or Network
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="table-dark" scope="row">
                      Netmask
                    </th>
                    <td className="table-dark">
                      {data.Supernet.Netmask} --- {data.Supernet.NetmaskLength}
                    </td>
                  </tr>

                  <tr>
                    <th className="table-dark" scope="row">
                      Wildcard
                    </th>
                    <td className="table-dark">{data.Supernet.Wildcard}</td>
                  </tr>
                  <tr>
                    <th className="bg-light"></th>
                    <td className="bg-light"></td>
                  </tr>
                  <tr>
                    <th className="table-dark" scope="row">
                      Network
                    </th>
                    <td className="table-dark">{data.Supernet.Network}</td>
                  </tr>
                  <tr>
                    <th className="table-dark" scope="row">
                      Broadcast
                    </th>
                    <td className="table-dark">{data.Supernet.Broadcast}</td>
                  </tr>
                  <tr>
                    <th className="table-dark" scope="row">
                      HostMin
                    </th>
                    <td className="table-dark">{data.Supernet.HostMin}</td>
                  </tr>
                  <tr>
                    <th className="table-dark" scope="row">
                      HostMax
                    </th>
                    <td className="table-dark">{data.Supernet.HostMax}</td>
                  </tr>
                  <tr>
                    <th className="table-dark" scope="row">
                      HostsNet
                    </th>
                    <td className="table-dark">
                      {data.Supernet.HostsNet}
                      <span style={{ color: "#D35400", marginLeft: "0.5em" }}>
                        ({data.IsPrivate ? "Private" : "Public"})
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    );
  } else if (data.hasOwnProperty("Address")) {
    return (
      <>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="table-dark text-light" scope="col">
                  Address Name
                </th>
                <th className="table-dark  text-light" scope="col">
                  Host or Network
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="table-active" scope="row">
                  Address
                </th>
                <td className="table-active">{data.Address}</td>
              </tr>
              <tr>
                <th className="table-active" scope="row">
                  Netmask
                </th>
                <td className="table-active">
                  {data.Netmask} --- {data.NetmaskLength}
                </td>
              </tr>

              <tr>
                <th className="table-active" scope="row">
                  Wildcard
                </th>
                <td className="table-active">{data.Wildcard}</td>
              </tr>
              <tr>
                <th className="bg-dark"></th>
                <td className="bg-dark"></td>
              </tr>
              <tr>
                <th className="table-active text-primary" scope="row">
                  Network
                </th>
                <td className="table-active text-primary">{data.Network}</td>
              </tr>
              <tr>
                <th className="table-active text-primary" scope="row">
                  Broadcast
                </th>
                <td className="table-active text-primary">{data.Broadcast}</td>
              </tr>
              <tr>
                <th className="table-active text-primary" scope="row">
                  HostMin
                </th>
                <td className="table-active text-primary">{data.HostMin}</td>
              </tr>
              <tr>
                <th className="table-active text-primary" scope="row">
                  HostMax
                </th>
                <td className="table-active text-primary">{data.HostMax}</td>
              </tr>
              <tr>
                <th className="table-active text-primary" scope="row">
                  HostsNet
                </th>
                <td className="table-active text-primary">
                  {data.HostsNet}
                  <span style={{ color: "#D35400", marginLeft: "0.5em" }}>
                    ({data.IsPrivate ? "Private" : "Public"})
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  } else return "";
};

export default Result;
