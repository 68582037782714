import React from "react";
import { Formik } from "formik";
import "../index.css";
const CustomForm = ({ handleSubmit }) => {
  return (
    <Formik
      initialValues={{ address: "", netmask_1: "", netmask_2: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.address) {
          errors.address = "Address ni kiriting!";
        } else if (
          !/^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])[.]([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])[.]([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])[.]([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/.test(
            values.address
          )
        ) {
          errors.address = "Address Notog'ri kiritilgan";
        }
        if (!values.netmask_1) {
          errors.netmask_1 = "NetMask ni kiriting!";
        } else if (!/^([0-9]{0,1})\d$/.test(values.netmask_1)) {
          errors.netmask_1 = "NetMask notog'ri kiritilgan";
        }
        return errors;
      }}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <div className="form-group">
          <form className="form-group" onSubmit={handleSubmit} action="get">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label className="form-label" htmlFor="address">
                  Address (Host or Network)
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  placeholder="Ip Address"
                />
                <span className="text-danger">
                  {errors.address && touched.address && errors.address}
                </span>
              </div>

              <div className="col-sm-12 col-md-3 col-lg-2">
                <label className="form-label" htmlFor="netmask_1">
                  Netmask (i.e. 24)
                </label>
                <input
                  className="form-control "
                  name="netmask_1"
                  type="text"
                  value={values.netmask_1}
                  onChange={handleChange}
                  placeholder="NetMask"
                />
                <span className="text-danger">
                  {errors.netmask_1 && touched.netmask_1 && errors.netmask_1}
                </span>
              </div>

              <div className="col-sm-12 col-md-5 col-lg-5">
                <label className="form-label" htmlFor="netmask_2">
                  Netmask for sub/supernet (optional)
                </label>
                <input
                  className="form-control"
                  name="netmask_2"
                  type="text"
                  value={values.netmask_2}
                  onChange={handleChange}
                  placeholder="Netmask for subnet or supernet"
                />
              </div>
            </div>

            <button className="btn btn-primary mt-3" type="submit">
              Calculate
            </button>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default CustomForm;
