import React, { useState } from "react";

import "./index.css";
import { ToastContainer } from "react-toastify";
import CustomForm from "./components/Form";
import Result from "./components/Result";
import { getData } from "./components/config/api";
import brand from "./assets/brand.png";

function App() {
  const [data, setdata] = useState([]);
  const [version, setVersion] = useState();

  const handleSubmit = (item) => {
    getData(
      `${item.address}/${item.netmask_1}/${item.netmask_2}`,
      setdata,
      setVersion
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="container bg-dark pt-1 h-100 pb-4">
        <section className="rounded-top d-flex bg-light mt-2">
          <div className="pl-2 pt-2">
            <img src={brand} alt="img" />
          </div>
          <div className="ml-2 mt-3">
            <h1 style={{ fontSize: "20px" }}>FizmaSoft Ip Calculator</h1>
          </div>
        </section>
        <section className="form-container mt-2">
          <CustomForm handleSubmit={handleSubmit} />
        </section>

        <section className="result-container">
          <Result data={data} />
        </section>
        <footer>
          <div className="mt-4">
            <h5 className="text-light">
              Version:
              <span>{version === undefined ? "0.0.1" : version}</span>
            </h5>
          </div>
          <h6 className="text-light"> © 2020 Fizmasoft</h6>
        </footer>
      </div>
    </>
  );
}

export default App;
