import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
const baseUrl = "https://ipcalc.fizmasoft.uz/api/";

export const getData = (url, data, version) => {
  axios.get(`${baseUrl}${url}`).then((res) => {
    if (res.data.status === 200) {
      data(res.data.Network);
      version(res.data.version);
    } else if (res.data.status === 400) {
      return toast.error(res.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });
};
